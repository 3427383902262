<template>
  <section>
    <div class="row">
      <div class="col-md-6">
        <ProcessAreaTable
          v-if="process_areas && !unselecting"
          class="process-area-table"
          v-bind:process_areas="process_areas"
          v-on:select="select"
          v-on:unselect="close"
          v-on:create="create"
        />
      </div>
      <div class="col-md-6" v-if="formProcessArea">
        <FormProcessArea
          data-testid="process-area-form"
          v-model="formProcessArea"
        />
        <hr />
        <div class="row">
          <div class="form-group col-sm-3">
            <button
              class="btn btn-default form-control"
              data-testid="cancel"
              v-bind:disabled="busy"
              v-on:click.prevent.stop="close"
            >
              {{ $t("cancel") }}
            </button>
          </div>
          <div class="form-group col-sm-3 col-sm-offset-3">
            <button
              class="btn form-control btn-default"
              data-testid="remove"
              v-if="
                process_area &&
                  process_area.id &&
                  $can('manage', 'AreaDeProcessoCadastro')
              "
              v-bind:disabled="busy"
              v-on:click.prevent.stop="remove"
            >
              <i class="fa fa-trash"></i>
              {{ $t("delete") }}
            </button>
          </div>
          <div class="form-group col-sm-3">
            <button
              class="btn btn-primary"
              style="width: 100%"
              data-testid="save"
              v-bind:disabled="busy || !isValid"
              v-on:click.prevent.stop="save"
              v-if="$can('manage', 'AreaDeProcessoEscrita')"
            >
              <i class="fa fa-save"></i>
              {{ $t("save") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import MixinAlert from "@/project/mixin-alert.js";
import FormProcessArea from "./form-process-area.vue";
import ProcessAreaTable from "./process-area-table.vue";
import ProcessArea from "@/services/process-area.js";

function getDefaultData() {
  return {
    busy: false,
    process_area: null,
    unselecting: false,
    formProcessArea: null // model coupled to the form
  };
}

export default {
  name: "ProcessAreaDisplay",
  mixins: [MixinAlert],
  components: {
    FormProcessArea,
    ProcessAreaTable
  },
  data() {
    return getDefaultData();
  },
  watch: {
    busy(n) {
      this.$emit("loading", n);
    }
  },
  computed: {
    process_areas() {
      return this.$store.getters["processArea/processAreas"] || [];
    },
    contract_id() {
      return this.$store.getters["user/loggedUser"].contract_id || 1;
    },
    payload() {
      let self = this;
      let payload = {
        name: self.formProcessArea.name,
        description: self.formProcessArea.description,
        contract_id: this.contract_id
      };
      if (self.process_area && "id" in self.process_area) {
        payload.id = self.process_area.id;
        payload.etag = self.process_area.etag;
      }
      return payload;
    },
    isValid() {
      return (
        this.formProcessArea &&
        this.formProcessArea.name &&
        this.formProcessArea.description
      );
    }
  },
  methods: {
    select(entry) {
      let self = this;
      self.$set(self, "process_area", entry);
      self.$set(self, "formProcessArea", null);
      self.$nextTick(function() {
        self.$set(self, "formProcessArea", {
          name: entry.name,
          description: entry.description
        });
      });
    },
    unselect() {
      let self = this;
      self.$set(self, "formProcessArea", null);
      self.process_area = null;
      self.unselecting = true;
      self.$nextTick(function() {
        self.unselecting = false;
      });
    },
    create() {
      let self = this;
      self.$set(self, "process_area", null);
      self.$set(self, "formProcessArea", null);
      self.$nextTick(function() {
        self.$set(self, "formProcessArea", {
          name: "",
          description: ""
        });
      });
    },
    save() {
      let self = this;
      self
        .validatePrivileges("manage", "AreaDeProcessoEscrita")
        .then((isOk) => {
          if (isOk) {
            let srv = new ProcessArea();
            let payload = self.payload;
            self.busy = true;
            srv
              .save(payload)
              .then((response) => {
                if (typeof response == "string") {
                  self.alert = {
                    title: self.$t("item_could_not_be_saved"),
                    text: ret,
                    type: "error"
                  };
                  self.showAlert();
                } else {
                  self.fetch();
                  self.alert = {
                    title: self.$t("saved"),
                    text: self.$t("you_have_saved_n_items", { count: 1 }),
                    type: "success"
                  };
                  self.showAlert(self.close);
                }
              })
              .catch((message) => {
                self.alert = {
                  title: self.$t("item_could_not_be_saved"),
                  text: message,
                  type: "error"
                };
                self.showAlert();
              })
              .then(() => (self.busy = false));
          }
        });
    },
    remove() {
      let self = this;
      self
        .validatePrivileges("manage", "AreaDeProcessoEscrita")
        .then((isOk) => {
          if (isOk) {
            self
              .$swal({
                title: self.$t("are_you_sure"),
                text: self.$t("you_wont_be_able_to_revert_this"),
                icon: "warning",
                buttons: [self.$t("cancel"), self.$t("yes_delete_it")]
              })
              .then(function(isConfirm) {
                if (isConfirm) {
                  self.doRemove(self.payload, new ProcessArea()); // child must implement it
                }
              });
          }
        });
    },
    doRemove: function(payload, srv) {
      let self = this;
      self.busy = true;
      srv.remove(payload).then((ret) => {
        self.busy = false;
        if (self.validateDeleteResponse(ret)) {
          self.exitSuccess = true;
          self.fetch();
          self.showAlert(self.close);
        } else {
          self.showAlert();
        }
      });
    },
    close() {
      this.unselect();
    },
    fetch() {
      this.busy = true;
      this.$store
        .dispatch("processArea/fetchProcessAreas")
        .then(() => (this.busy = false));
    }
    // save(){
    //   let self = this;
    //   let entry = {
    //     name: self.formProcessArea.name,
    //     description: self.formProcessArea.description,
    //     contract_id: this.contract_id
    //   };
    //   if (self.process_area && ("id" in self.process_area)) {
    //     entry.id = self.process_area.id;
    //   }
    //   self.$store.dispatch("processArea/save", entry);
    // },
    // remove(){
    //   let self=this;
    //   self.validatePrivileges("manage", "MembroDeContratoEscrita").then(isOk => {
    //     if (isOk) {
    //       self.$swal({
    //         title: self.$t("are_you_sure"),
    //         text: self.$t("you_wont_be_able_to_revert_this"),
    //         icon: 'warning',
    //         buttons:[self.$t("cancel"),self.$t("yes_delete_it")]
    //       }).then(function(isConfirm) {
    //         if (isConfirm) {
    //           self.$store.dispatch("processArea/remove", self.processArea);
    //         }
    //       });
    //     }
    //   });
    // },
    // close(){
    //   this.unselect();
    // }
  },
  created() {
    if (!this.$store.getters["processArea/isReady"]) this.fetch();
  }
};
</script>

<style scoped>
section > .row,
section > .row > [class|="col"] {
  height: inherit;
}

section .process-area-table {
  height: 98%;
}
</style>
